import HandlerApp from "../../../utils/handlerApp";
import NoDataTables from "../../noDataTable/NoDataTables";
import SelectLocation from "../../molecules/SelectLocation";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useContext, useEffect, useState } from "react";
import { LocationContext } from "../../../context/LocationContext";

const CloseCashier = () => {

    const endpoint      = "/orders";
    const endpointClose = "close/orders";
    const [t, i18n]     = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const profile       = handlerApp.getProfile();
    const { id }        = useParams();

    const [data, setData] = useState();
    const [cashTotal, setCashTotal] = useState(0);
    const [createdBy, setCreatedBy] = useState("");
    const [valorTotal, setValorTotal] = useState(0);
    const [externalTotal, setExternalTotal] = useState(0);
    const [cashOrders, setCashOrders] = useState([]);
    const [creditTotal, setCreditTotal] = useState(0);
    const [externalOrders, setExternalOrders] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [creditOrders, setCreditOrders] = useState([]);
    const [employees, setEmployees] = useState(['Total']);
    const [disableButton, setDisableButton] = useState(false)
    const [closingCasherDatetime, setClosingCasherDatetime] = useState("");
    
    const { location, company } = useContext(LocationContext);


    useEffect(() => {
        setData(undefined)
        setCreditOrders([]);
        setExternalOrders([]);
        setCashOrders([]);
        setCashOrders([]);
        setCreditTotal(0);
        setExternalTotal(0);
        setCashTotal(0);
        setValorTotal(0);
        setEmployees((['Total']));
        setCreatedBy("");
        setClosingCasherDatetime("");
        setCurrentIndex(0);
        if(profile < 3){
            if (company == "") {
                if (handlerApp.getLocation() === null || handlerApp.getLocation() === "") {
                    handlerApp.showAlertCompany();
                }
            }else if( location === ""){
                if (handlerApp.getLocation() === null || handlerApp.getLocation() === "") {
                    handlerApp.showAlertLocation();
                }
            } else {
                if (!id) {
                    sendRequestOrders('C');
                } else {
                    sendRequestOrdersByClosingCashierId()
                }
            }
        }else{
            if (location === "") {
                if (handlerApp.getLocation() === null || handlerApp.getLocation() === "") {
                    handlerApp.showAlertLocation();
                }
            } else {
                if (!id) {
                    sendRequestOrders('C');
                } else {
                    sendRequestOrdersByClosingCashierId()
                }
            }
        }
    }, [location, company, id])


    useEffect(() => {
        if (creditOrders.length === 0 && externalOrders.length === 0 && cashOrders.length === 0) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [creditOrders, externalOrders, cashOrders]);

    useEffect(() => {
        filterDataByEmployee(employees[currentIndex])
    }, [currentIndex])

    const sendRequestOrders = (state) => {
        handlerApp.setLoader(true);
        setCreditOrders([]);
        setExternalOrders([]);
        setCashOrders([]);
        api.get(endpoint, { action: 'getOrdersByLocation', state: state, txStatus: 'SUCCESS', location })
            .then(response => {
                if (response.success) {
                    setData(response.data);
                    const employee = ['Total', ...new Set(response.data.map(order => order.person_firstname + ' ' + order.person_surname))];
                    const totalCreditOrders = response.data.filter(order => order.tx_method === 'credit_card' || order.tx_method === 'debit_card');
                    const externalOrders = response.data.filter(order => order.tx_method === 'EXTERNAL PAY');
                    const totalCashOrders = response.data.filter(order => order.tx_method === 'CASH');
                    const creditTotal = totalCreditOrders.reduce((accumulator, item) => accumulator + item.tx_amount, 0);
                    const externalTotal = externalOrders.reduce((accumulator, item) => accumulator + item.tx_amount, 0);
                    const cashTotal = totalCashOrders.reduce((accumulator, item) => accumulator + item.tx_amount, 0);
                    const valorTotal = response.data.reduce((accumulator, item) => accumulator + item.tx_amount, 0);
                    setCreditOrders(totalCreditOrders);
                    setExternalOrders(externalOrders);
                    setCashOrders(totalCashOrders);
                    setCashOrders(totalCashOrders);
                    setCreditTotal(creditTotal);
                    setExternalTotal(externalTotal);
                    setCashTotal(cashTotal);
                    setValorTotal(valorTotal)
                    setEmployees(employee);
                }
                handlerApp.setLoader(false);
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
            })
    }

    const sendRequestOrdersByClosingCashierId = () => {
        handlerApp.setLoader(true);
        setCreditOrders([]);
        setExternalOrders([]);
        setCashOrders([]);
        api.get('/close_cashier/view/' + id, { location })
            .then(response => {
                if (response.success) {
                    setData(response.data);
                    setClosingCasherDatetime(response.data[0].closingcasher_datetime);
                    setCreatedBy(response.data[0].created_by);
                    const employee = ['Total', ...new Set(response.data.map(order => order.person_firstname + ' ' + order.person_surname))];
                    const totalCreditOrders = response.data.filter(order => order.tx_method === 'credit_card' || order.tx_method === 'debit_card');
                    const totalexternalOrders = response.data.filter(order => order.tx_method === 'EXTERNAL PAY');
                    const totalCashOrders = response.data.filter(order => order.tx_method === 'CASH');
                    const creditTotal = totalCreditOrders.reduce((accumulator, item) => accumulator + item.tx_amount, 0);
                    const externalTotal = totalexternalOrders.reduce((accumulator, item) => accumulator + item.tx_amount, 0);
                    const cashTotal = totalCashOrders.reduce((accumulator, item) => accumulator + item.tx_amount, 0);
                    const valorTotal = response.data.reduce((accumulator, item) => accumulator + item.tx_amount, 0);
                    setCreditOrders(totalCreditOrders);
                    setExternalOrders(totalexternalOrders);
                    setCashOrders(totalCashOrders);
                    setCashOrders(totalCashOrders);
                    setCreditTotal(creditTotal);
                    setExternalTotal(externalTotal);
                    setCashTotal(cashTotal);
                    setValorTotal(valorTotal)
                    setEmployees(employee);
                }
                handlerApp.setLoader(false);
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
            })
    }
    

    const filterDataByEmployee = (employee) => {
        if (data) {
            if (employee == 'Total') {
                if (!id) {
                    sendRequestOrders('C');
                } else {
                    location && sendRequestOrdersByClosingCashierId()
                }
            } else {
                const dataByEmployee = data.filter(order => order.person_firstname + ' ' + order.person_surname === employee);
                const totalCreditOrders = dataByEmployee.filter(order => order.tx_method === 'credit_card' || order.tx_method === 'debit_card');
                const totalexternalOrders = dataByEmployee.filter(order => order.tx_method === 'EXTERNAL PAY');
                const totalCashOrders = dataByEmployee.filter(order => order.tx_method === 'CASH');
                const creditTotal = totalCreditOrders.reduce((accumulator, item) => accumulator + item.tx_amount, 0);
                const externalTotal = totalexternalOrders.reduce((accumulator, item) => accumulator + item.tx_amount, 0);
                const cashTotal = totalCashOrders.reduce((accumulator, item) => accumulator + item.tx_amount, 0);
                const valorTotal = dataByEmployee.reduce((accumulator, item) => accumulator + item.tx_amount, 0);
                setCreditOrders(totalCreditOrders);
                setExternalOrders(totalexternalOrders);
                setCashOrders(totalCashOrders);
                setCreditTotal(creditTotal);
                setExternalTotal(externalTotal);
                setCashTotal(cashTotal);
                setValorTotal(valorTotal)
            }
        }
    }

    const handleCloseCashier = () => {
        handlerApp.setLoader(true);
        setDisableButton(true)
        setCreditTotal(0);
        setExternalTotal(0);
        setCashTotal(0);
        setValorTotal(0)
        setEmployees(['Total'])
        setCurrentIndex(0)
        api.post(endpointClose, { data: data, location: location })
            .then(response => {
                if (response.success) {
                    sendRequestOrders('C')
                    setDisableButton(false)
                    handlerApp.showOk(response);
                } else {
                    handlerApp.setLoader(false);
                    setDisableButton(false)
                }
            })
            .catch(error => {
                setDisableButton(false)
                handlerApp.showError(t(error.message));
            })
    }

    const formatTimeTo12Hour = (time24) => {
        if (!time24) {
            return '';
        }
        const [hour, minutes] = time24.split(':');
        const hourInt = parseInt(hour, 10);
        const period = hourInt >= 12 ? 'PM' : 'AM';
        const hour12 = hourInt === 0 ? 12 : hourInt > 12 ? hourInt - 12 : hourInt;
        return `${hour12}:${minutes} ${period}`;
    };

    const renderOrderCards = (orders) => {
        if (orders.length === 0) {
            return (
                <div className="d-flex justify-content-center align-items-center orders-to-close mt-2">
                    <p className="text-order">{t('closeCashierScreen.noData')}</p>
                </div>
            );
        }

        return (<section className="mt-2">{orders.map((item, index) => (
            <div key={item.order_id} className="d-flex justify-content-evenly ">
                <p className="text-order">{t('closeCashierScreen.order') + ' ' + "#" + item.order_consecutive}</p>
                <p className="text-order bold">{handlerApp.formatCurrency(item.tx_amount)}</p>
                <p className="text-order">{formatTimeTo12Hour(item.hora)}</p>
            </div>
        ))}</section>)
    }


    const getCurrentDateTime = () => {
        const currentDate = new Date();
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const locale = i18n.language === 'es' ? 'es-ES' : 'en-US';
        const dateString = new Intl.DateTimeFormat(locale, options).format(currentDate);
        const timeString = currentDate.toLocaleTimeString(locale, { hour12: false });
        return { dateString, timeString };
    };

    const { dateString, timeString } = getCurrentDateTime();

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex < employees.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const formatDateTime = (datetime) => {
        if (!datetime) {
            return t('closeCashierScreen.noDateAvailable');
        }
        const dateObj = new Date(datetime);
        if (isNaN(dateObj.getTime())) {
            return t('closeCashierScreen.noDateAvailable');
        }
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const locale = i18n.language === 'es' ? 'es-ES' : 'en-US';
        const dateString = new Intl.DateTimeFormat(locale, options).format(dateObj);
        const timeString = dateObj.toLocaleTimeString(locale, { hour12: false });
        return `${dateString} ${timeString}`;
    };

    return (
        <>
            {location === "" && (
                <SelectLocation />
            )}
            {location !== "" && (
                <div className="app container">
                    <div className="title-section-forms">{t('closeCashierScreen.todaysMove')}</div>
                    {!id ? 
                        <div className="d-flex ml-1">
                            <p className="text-details">{dateString}</p>
                            <p className="text-details">{timeString}</p>
                        </div>
                        :
                        <div className="d-flex ml-1">
                            <p className="text-details">{createdBy}</p>
                            <p className="text-details">{closingCasherDatetime ? formatDateTime(closingCasherDatetime) : t('closeCashierScreen.noDateAvailable')}</p>
                        </div>
                    }
                    <p className="text-details ml-1" style={{marginTop:'-12px'}}>{t('description.closeCashier')}</p>
                    {data !== undefined ? 
                        <div className="container-table">
                            <section className="d-flex justify-content-center">
                                <button className="pagination-button" onClick={handlePrevious} style={{ display: currentIndex === 0 && 'none' }}>{'<'}</button>
                                <span className="col-3 d-flex mx-3 justify-content-center">
                                    <h2 className="tittle-position">{employees[currentIndex]}</h2>
                                </span>
                                <button className="pagination-button" onClick={handleNext} style={{ display: currentIndex === employees.length - 1 && 'none' }}>{'>'}</button>
                            </section>
                            <section className="col-12 d-flex flex-wrap mt-2 justify-content-around">
                                <div className="col-4 col-lg-card col-md-11 col-sm-11 section-forms close-card mb-3">
                                    <div className="col-12 tittle-position semi-light d-flex justify-content-center">{t('closeCashierScreen.creditDebit')}</div>
                                    <section className="orders-to-close">
                                        {renderOrderCards(creditOrders, t('order.credit'))}
                                    </section>
                                    <section className="section-bottom-card-close col-12 pt-2">
                                        <p className="total-text">{t('closeCashierScreen.revenue')}:</p>
                                        <p className="green-text">{handlerApp.formatCurrency(creditTotal)}</p>
                                    </section>
                                </div>
                                <div className="col-4 col-lg-card col-md-11 col-sm-11 section-forms close-card mb-3">
                                    <div className="col-12 tittle-position semi-light d-flex justify-content-center">{t('closeCashierScreen.cash')}</div>
                                    <section className="orders-to-close">
                                        {renderOrderCards(cashOrders, t('closeCashierScreen.cash'))}
                                    </section>
                                    <section className="section-bottom-card-close col-12 pt-2">
                                        <p className="total-text">{t('closeCashierScreen.revenue')}:</p>
                                        <p className="green-text">{handlerApp.formatCurrency(cashTotal)}</p>
                                    </section>
                                </div>
                                <div className="col-4 col-lg-card col-md-11 col-sm-11 col-xs-6 section-forms close-card mb-3">
                                    <div className="col-12 tittle-position semi-light d-flex justify-content-center">{t('closeCashierScreen.externalPay')}</div>
                                    <section className="orders-to-close">
                                        {renderOrderCards(externalOrders, t('order.debit'))}
                                    </section>
                                    <section className="section-bottom-card-close col-12 pt-2">
                                        <p className="total-text">{t('closeCashierScreen.revenue')}:</p>
                                        <p className="green-text">{handlerApp.formatCurrency(externalTotal)}</p>
                                    </section>
                                </div>
                            </section>
                            <center>
                                <div className="container-close-cashier--button bold">Total: {handlerApp.formatCurrency(valorTotal)}</div>
                                {(currentIndex == 0 && !id) && <button disabled={disableButton} className={disableButton ? "bold-btn-light mt-3 py-2 px-5 " : "bold-btn-dark mt-3 py-2 px-5 bold"} onClick={handleCloseCashier}>{t('closeCashierScreen.closeCahier')}</button>}
                            </center>
                        </div>
                        :
                        <div className="container bold-container-content">
                            <div className="container-table">
                                <NoDataTables/>
                            </div> 
                        </div>
                    }
                </div>
            )}
        </>
    )
}

export default CloseCashier;