import HandlerApp from "../../../utils/handlerApp";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import { useForm } from "react-hook-form";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LocationContext } from "../../../context/LocationContext";

const PrintersLocationsForm = () => {
    
    const endpoint   = 'printers';
    const api        = useFetch();
    const handlerApp = HandlerApp();
    const profile    = handlerApp.getProfile()
    const nav        = useNavigate();
    const { id }     = useParams();
    const { t }      = useTranslation("global");
    
    const { handleSubmit, formState: { errors }, setValue, register } = useForm()
    
    const [title, setTitle]                               = useState(t('printersLocations.createPrintersByLocations'));
    const [printers, setPrinters]                         = useState([]);
    const [locations, setLocations]                       = useState([]);
    const [description, setDescription]                   = useState(t(''));
    const [disableButton, setDisableButton]               = useState(false);
    const [companiesOptions, setCompaniesOptions]         = useState([]);
    const [disableSelectCompany, setDisableSelectCompany] = useState(false);
    const [formData, setFormData]                         = useState({
        location_id: '',
        company_id: '',
        printer_id: '',
        locationprinter_ip_direction: '',
        locationprinter_name: '',
    });

    const { companies } = useContext(LocationContext);

    useEffect(() => {
        handlerApp.setLoader(true);
        if (id !== undefined) {
            sendRequest();
            setDisableSelectCompany(true);
        } else {
            handlerApp.setLoader(false);
        }
    }, [])

    useEffect(() => {
        setTitle(t('printersLocations.createPrintersByLocations'));
        setDescription(t('printersLocations.createPrintersByLocationsDescription'));
        if (id !== undefined) {
            setTitle(t('printersLocations.updatePrintersByLocations'));
            setDescription(t('printersLocations.updatePrintersByLocationsDescription'))
        }
    }, [t])

    useEffect(() => {
        if (companies.length > 0) {
            const transformedCompanies = companies.map(company => ({
                code: company.company_id,
                name: company.company_name
            }));
            setCompaniesOptions(transformedCompanies)
        }
    }, [companies])

    useEffect(() => {
        if (profile > 2 && id === undefined) {
            getParamsUpdate()
        }
    }, [profile])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id,)
        .then(response => {
            if (response.success) {
                let data = response.data[0]
                getParamsUpdate(data.company_id);
                let finalData = filterData(data, formData);
                setFormData(finalData);
                setDisableButton(false)
            } else {
                handlerApp.handlerResponse(response);
                setDisableButton(true)
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    const getParamsUpdate = (companyId = null) => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate",
            company: companyId
        }).then(response => {
            if (response.success) {
                setLocations(handlerApp.getResultFromObject(response.data, 'locations'));
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });

        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    const handleSubmitLocal = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
            } else {
                handlerApp.handlerResponse(response);
            }
            handlerApp.setLoader(false);
            nav("/" + endpoint);
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    const handleUpdate = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    const handleKeyDow = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    };

    const handleSelectCompany = (val) => {
        setFormData(prevState => ({
            ...prevState,
            location_id: ''

        }));
        getParamsUpdate(val);
        setLocations([]);
    }

    return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
                <p>{description} </p>
            </div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitLocal : handleUpdate)} onKeyDown={handleKeyDow}>
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-12">
                            <TextField
                                label={t('printersLocations.nameToPrinter')+" *"} variant="standard" size="small"
                                {...register("locationprinter_name", { required: true, maxLength: 80 })}
                                value={formData.locationprinter_name}
                                onChange={handleForm}
                                error={errors.locationprinter_name != null}
                                helperText={
                                    errors.locationprinter_name?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.locationprinter_name?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 80  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>
                        {
                            profile < 3 && 
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <BoldSelectValidated
                                    title={t('company') + " *"}
                                    value={formData.company_id ?? ''}
                                    name="company_id"
                                    options={companiesOptions}
                                    onChange={handleForm}
                                    register={register}
                                    errors={errors}
                                    dependency={handleSelectCompany}
                                />
                            </div>
                        }
                        <div className="col-md-6 col-lg-6 col-sm-12">
                            <BoldSelectValidated
                                title={t('locationComponent.location') + " *"}
                                value={formData.location_id ?? ''}
                                name="location_id"
                                options={locations}
                                onChange={handleForm}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>

                        <div className="col-md-6 col-lg-6 col-sm-12">
                            <TextField
                                label={t('printersLocations.ipAddress')} variant="standard" size="small"
                                {...register("locationprinter_ip_direction", { maxLength: 20 })}
                                value={formData.locationprinter_ip_direction}
                                onChange={handleForm}
                                error={errors.locationprinter_ip_direction != null}
                                helperText={
                                    errors.locationprinter_ip_direction?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 20  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>


            </div>
        </div>
    )
}
export default PrintersLocationsForm;