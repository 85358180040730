import { useContext, useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { AppContext } from "../../../context/AppContext";
import { LocationContext } from "../../../context/LocationContext";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InputAdornment from '@mui/material/InputAdornment';
import BoldMultiplesSelect from "../../../utils/BoldMultiplesSelects";

const ProductLocation2Form = () => {

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const endpoint = 'configuration/locations/products';
    const api = useFetch();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const profile = handlerApp.getProfile()

    const nav = useNavigate();
    const { id } = useParams();
    const { t } = useTranslation("global");

    const [title, setTitle] = useState('');
    const [products, setProducts] = useState([]);
    const [printers, setPrinters] = useState([]);
    const [taxgroup, setTaxesGroup] = useState([]);
    const [locations, setLocations] = useState([]);
    const [description, setDescription] = useState('');
    const [endDiscount, setEndDiscount] = useState("");
    const [startDiscount, setStartDiscount] = useState("");
    const [disableButton, setDisableButton] = useState(false);
    const [locationSelected, setLocationSelected] = useState();
    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [printersLocations, setPrintersLocations] = useState([]);
    const [disableSelectCompany, setDisableSelectCompany] = useState(false);


    useEffect(() => {
        if (startDiscount?.hasOwnProperty('_d')) {
            setFormData({ ...formData, productlocation_discount_start: startDiscount?.format('YYYY-MM-DD') });
        }else{
            setFormData({ ...formData, productlocation_discount_start: null });
        }
    }, [startDiscount]);

    useEffect(() => {
        if (endDiscount?.hasOwnProperty('_d')) {
            setFormData({ ...formData, productlocation_discount_end: endDiscount?.format('YYYY-MM-DD') });
        }else{
            setFormData({ ...formData, productlocation_discount_end: null});
        }
    }, [endDiscount]);

    //Permisos
    const [permissions, setPermissions] = useState([]);
    const { app } = useContext(AppContext);
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        setTitle(t('productLocationComponent.create'));
        setDescription(t('description.productLocation_create'));
        if (id !== undefined) {
            setTitle(t('productLocationComponent.update'));
            setDescription(t('description.productLocation_update'));
        }
    }, [t])

    // location
    const { location, companies, locations: locationsContext } = useContext(LocationContext);

    useEffect(() => {
        if (companies.length > 0) {
            const transformedCompanies = companies.map(company => ({
                code: company.company_id,
                name: company.company_name
            }));
            setCompaniesOptions(transformedCompanies)
        }   
    }, [companies])

    useEffect(() => {
        if (profile > 2) {
            if (locationsContext.length > 0) {
                const transformedlocations = locationsContext.map(location => ({
                    code: location.location_id,
                    name: location.location_name
                }));
                setLocations(transformedlocations)
            }   
        }
    }, [locationsContext])



    useEffect(() => {
        if (id !== undefined) {
            sendRequest();
            setDisableSelectCompany(true)
        } else {
            handlerApp.setLoader(false);
        }
    }, [])

    useEffect(() => {
        if (profile > 2 && id == undefined) {
            getParamsUpdate()
        }
    }, [profile])


    // Data
    const [formData, setFormData] = useState({
        product_id: null,
        company_id: '',
        location_id: '',
        productlocation_price: '',
        taxgroup_id: '',
        productlocation_discount: '0.00',
        productlocation_discount_start: null,
        productlocation_discount_end: null,
        productlocation_stock: '',
        productlocation_min_stock: '',
        productlocation_max_stock: '',
        productlocation_not_available: false,
        printer_id: null,
        productlocation_use_stock: false,
        productlocation_printers: [],
    });

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        if (d.productlocation_discount_start != null) {
            setStartDiscount(moment(d.productlocation_discount_start));
        }
        let auxPrinters = [];
        data.productlocation_printers?.forEach(e => {
            auxPrinters.push(e.code);
        })
        d.productlocation_printers = auxPrinters;

        if (d.productlocation_discount_end != null) {
            setEndDiscount(moment(d.productlocation_discount_end));
        }
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            if (data[key] != null && data[key] != undefined) {
                finalData[key] = data[key];
            } else {
                finalData[key] = formData[key];
            }
            setValue(key, data[key]);
        }


        return finalData;
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    getParamsUpdate(data.location_id)
                    setLocationSelected(data.location_id)
                    setDisableButton(false)
                } else {
                    setDisableButton(true)
                    handlerApp.setLoader(false);
                    handlerApp.handlerResponse(response);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const getParamsUpdate = (val = null) => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: 'getParamsUpdate',
            location: val == null ? location : val
        })
        .then(response => {
            if (response.success) {
                setProducts(handlerApp.getResultFromObject(response.data, 'products'));
                setLocations(handlerApp.getResultFromObject(response.data, 'locations'))
                setTaxesGroup(handlerApp.getResultFromObject(response.data, 'tax_group'));
                setPrinters(handlerApp.getResultFromObject(response.data, 'printers'))
                setPrintersLocations(handlerApp.getResultFromObject(response.data, 'printers_locations'))
                handlerApp.setLoader(false);
                
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        let value = e.target.value;
    
        const regex = /^\d+(\.\d{0,2})?$/;

        const isInputNumber = key === "productlocation_price" || key === "productlocation_discount" || key === "productlocation_stock" || key === "productlocation_min_stock" || key === "productlocation_max_stock";
        
        if (key === 'printer_id' && value === '') {
            value = null;
        }
    
        if (!isInputNumber || (isInputNumber && (regex.test(value) || value === ''))) {
            updateFormData(key, value,dependency);
        }
    }

    const updateFormData = (key, value,dependency) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value,
            category_id: key === 'category_id' ? null : prevState.category_id
        }));

        setValue(key, value);
    
        if (dependency) {
            dependency(value);
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }
    const handleSubmitLocal = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...formData,
            location_id: locationSelected == null ? location : locationSelected
        }
        api.post(endpoint, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }
    const handleUpdate = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...formData, location_id: locationSelected == null ? location : locationSelected
        }
        api.put(endpoint + "/" + id, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    const handleCheck = (e) => {
        let key = e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.checked
        });
    }

    const handleKeyDow = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const getLocations = (company, fromSelect = false) => {
        if ((company !== undefined && company !== "")) {
            handlerApp.setLoader(true);
            api.get("administration/users", {
                action: "getLocation",
                company: company,

            })
            .then(response => {
                if (response.success) {
                    setLocations(response.data);
                    if (fromSelect) {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.setLoader(false);
                    handlerApp.handlerResponse(response);
                }
            })
            .catch(error => {
                handlerApp.showError(error);
            })
        }
        
    }
    const handleSelectCompany = (val) => {
        setFormData(prevState => ({
            ...prevState,
            printer_id: '',
            location_id: ''

        }));
        setLocations([]);
        setProducts([]);
        getLocations(val,true)
    }

    const handleSelectlocation = (val) => {
        getParamsUpdate(val)
        setLocationSelected(val) 
    }

    const handleDecimalInput = (e) => {
        const name = e.target.name;
        let rawValue = 0;
        if (e.target.value != "") {
            rawValue = e.target.value.replace(/\D/g, ""); 
        }
        const decimalValue = (parseInt(rawValue, 10) / 100).toFixed(2);
        
        updateFormData(name, decimalValue)
    };

    return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
                <p>{description}</p>
            </div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitLocal : handleUpdate)} noValidate onKeyDown={handleKeyDow}>
                    <div className="row">
                        {profile <= 2 && (
                            <div className="col-md-6 mb-2">
                                <BoldSelectValidated
                                    title={t('companyComponent.company') + " *"}
                                    value={formData.company_id ?? ''}
                                    name="company_id"
                                    options={companiesOptions}
                                    onChange={handleForm}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                    dependency={handleSelectCompany}
                                    disable={disableSelectCompany}
                                />
                            </div>
                        )}
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('locationComponent.location') + " *"}
                                value={formData.location_id ?? ''}
                                name="location_id"
                                options={locations}
                                onChange={handleForm}
                                register={register}
                                errors={errors}
                                required={true}
                                dependency={handleSelectlocation}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('productComponent.product') + " *"}
                                value={formData.product_id ?? ''}
                                name="product_id"
                                options={products}
                                onChange={handleForm}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('productComponent.price')}
                                variant="standard"
                                size="small"
                                {...register("productlocation_price", {
                                    min: 0,
                                    max: 2147483647
                                })}
                                onChange={handleDecimalInput}
                                error={errors.productlocation_price != null}
                                type="number"
                                multiline={true}
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 100
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>
                                    )
                                }}
                                helperText={
                                    errors.productlocation_price?.type === 'min'
                                        ? `${t('validationErrorMessages.minNumber')} 0 `
                                        : errors.productlocation_price?.type === 'max'
                                            ? `${t('validationErrorMessages.MaxNumber')} 2147483647 `
                                            : ""
                                }
                            />
                                <div className="description-input">{t('productLocationComponent.editPrice')}</div>
                            </div>

                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('taxesComponent.taxgroup_name')}
                                value={formData.taxgroup_id ?? ''}
                                name="taxgroup_id"
                                onChange={handleForm}
                                options={taxgroup}
                                register={register}
                                errors={errors}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('productComponent.discount')}
                                variant="standard"
                                size="small"
                                {...register("productlocation_discount", {
                                    min: 0,
                                    max: 100
                                })}
                                value={formData.productlocation_discount ?? ''}
                                onChange={handleDecimalInput}
                                error={errors.productlocation_discount != null}
                                type="number"
                                multiline={true}
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 100
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <h4 className="percentage-icon">%</h4>

                                        </InputAdornment>
                                    )
                                }}
                                helperText={
                                    errors.productlocation_discount?.type === 'min'
                                        ? `${t('validationErrorMessages.minNumber')} 0 `
                                        : errors.productlocation_discount?.type === 'max'
                                            ? `${t('validationErrorMessages.MaxNumber')} 100 `
                                            : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <DatePicker
                                label={t('productLocationComponent.discount_start')}
                                format="YYYY/MM/DD"
                                name="productlocation_discount_start"
                                variant="standard"
                                onChange={(val) => { setStartDiscount(val) }}
                                value={startDiscount}
                                slotProps={{ textField: { size: 'small', variant: "standard" } }}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <DatePicker
                                label={t('productLocationComponent.discount_end')}
                                format="YYYY/MM/DD"
                                name="productlocation_discount_end"
                                variant="standard"
                                onChange={(val) => { setEndDiscount(val) }}
                                value={endDiscount}
                                minDate={startDiscount}
                                slotProps={{ textField: { size: 'small', variant: "standard" } }}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('printersComponent.printer') + " (" + t('optional') + ")"}
                                value={formData.printer_id ?? ''}
                                name="printer_id"
                                onChange={handleForm}
                                options={printers}
                                register={register}
                                errors={errors}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <BoldMultiplesSelect
                                title={t("printersLocations.printerAssociated") + " (" + t('optional') + ")"} 
                                name="productlocation_printers" 
                                value={formData.productlocation_printers ?? ''} 
                                onChange={handleForm} 
                                options={printersLocations} 
                            />
                        </div>

                        {formData.productlocation_use_stock == 1 && (
                            <>
                                <div className="col-md-12 mb-2">
                                    <TextField
                                        label={t('productLocationComponent.stock')}
                                        variant="standard"
                                        size="small"
                                        {...register("productlocation_stock", {
                                            min: 0,
                                            max: 2147483647
                                        })}
                                        value={formData.productlocation_stock ?? ''}
                                        onChange={handleForm}
                                        error={errors.productlocation_stock != null}
                                        type="number"
                                        multiline={true}
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                                max: 2147483647
                                            }
                                        }}
                                        helperText={
                                            errors.productlocation_stock?.type === 'min'
                                                ? `${t('validationErrorMessages.minNumber')} 0 `
                                                : errors.productlocation_stock?.type === 'max'
                                                    ? `${t('validationErrorMessages.MaxNumber')} 2147483647 `
                                                    : ""
                                        }
                                    />

                                </div>
                                <div className="col-md-6 mb-2">

                                    <TextField
                                        label={t('productLocationComponent.min_stock')}
                                        variant="standard"
                                        size="small"
                                        {...register("productlocation_min_stock", {
                                            min: 0,
                                            max: 2147483647
                                        })}
                                        value={formData.productlocation_min_stock ?? ''}
                                        onChange={handleForm}
                                        error={errors.productlocation_min_stock != null}
                                        type="number"
                                        multiline={true}
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                                max: 2147483647
                                            }
                                        }}
                                        helperText={
                                            errors.productlocation_min_stock?.type === 'min'
                                                ? `${t('validationErrorMessages.minNumber')} 0 `
                                                : errors.productlocation_min_stock?.type === 'max'
                                                    ? `${t('validationErrorMessages.MaxNumber')} 2147483647 `
                                                    : ""
                                        }
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <TextField
                                        label={t('productLocationComponent.max_stock')}
                                        variant="standard"
                                        size="small"
                                        {...register("productlocation_max_stock", {
                                            min: 0,
                                            max: 2147483647
                                        })}
                                        value={formData.productlocation_max_stock ?? ''}
                                        onChange={handleForm}
                                        error={errors.productlocation_max_stock != null}
                                        type="number"
                                        multiline={true}
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                                max: 100
                                            }
                                        }}
                                        helperText={
                                            errors.productlocation_max_stock?.type === 'min'
                                                ? `${t('validationErrorMessages.minNumber')} 0 `
                                                : errors.productlocation_max_stock?.type === 'max'
                                                    ? `${t('validationErrorMessages.MaxNumber')} 2147483647 `
                                                    : ""
                                        }
                                    />
                                </div>
                            </>
                        )}
                        
                        <div className="col-md-6 mb-2">
                            <div className="row np-row">
                                <div className="col-md-12">
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                name="productlocation_use_stock" 
                                                checked={(formData.productlocation_use_stock === 0 || !formData.productlocation_use_stock) ? false : true} 
                                                onChange={handleCheck} 
                                            />
                                        } 
                                        label={t('productComponent.stockProduct')} 
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mb-2">
                            <div className="row np-row">
                                <div className="col-md-12">
                                    <FormControlLabel control={<Checkbox name="productlocation_not_available" checked={(formData.productlocation_not_available === 0 || !formData.productlocation_not_available) ? false : true} onChange={handleCheck} />} label={t('productComponent.productNotAvailable')} /><br />
                                    <span className="text-gray light small-text">{t('productComponent.notAvailableDetail')}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ProductLocation2Form;