import { Alert, CircularProgress, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../assets/css/login.css"
import logo from "../../assets/img/bravus-azul-rojo.png";
import logoNegativo from "../../assets/img/Logo-principal-negativo.png";
import  { useFirebase }  from "../../hooks/useFirebase";


const Login = () => {
    const [t, i18n] = useTranslation("global");
    const [loginForm, setLoginForm] = useState({
        fuser: "",
        fpass: ""
    });
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();
    const [logged, setLogged] = useState(false);
 
    const handleLogin = (e) => {
        let key = e.target.name;
        setLoginForm({
            ...loginForm,
            [key]: e.target.value
        });
        setError();
    }

    const nav = useNavigate();
    const firebase = useFirebase();

    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        toggleDarkMode();
    }, []);

    const toggleDarkMode = (toggleMode = null) => {
        const htmlElement = document.documentElement;
        let darkMode = localStorage.getItem('darkMode');

        if(toggleMode){
            darkMode = darkMode === 'dark' ? '' : 'dark';
        }
        
        
        if (darkMode === 'dark') {
            htmlElement.classList.add('dark');
            setIsDarkMode(true);
            localStorage.setItem('darkMode', 'dark');
        } else if (darkMode === '') {
            htmlElement.classList.remove('dark');
            setIsDarkMode(false);
            localStorage.setItem('darkMode', '');
        } else {
            const themeUser = window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (themeUser) {
                htmlElement.classList.add('dark');
                setIsDarkMode(true);
                localStorage.setItem('darkMode', 'dark');
            } else {
                htmlElement.classList.remove('dark');
                setIsDarkMode(false);
                localStorage.setItem('darkMode', '');
            }
        }
    }
      

    useEffect(() => {
        // Check if the user is already authenticated
        if (localStorage.getItem("token")) {
            nav("/"); // Or wherever your dashboard route is
        }else{
            setLogged(true);
        }
    },[]);

    const handleFormLogin = (e) => {
        e.preventDefault();
        setLoader(true);
        let fpass = btoa(unescape(encodeURIComponent(btoa(unescape(encodeURIComponent(loginForm.fpass))))));

        axios.post(process.env.REACT_APP_URL_BACKEND + '/login', {
            fuser: loginForm.fuser,
            fpass: fpass,
            fPlatform: 'X-Web-App'
        }).then(response => {
            if(response.data.success){
                localStorage.setItem('user', response.data.user);
                localStorage.setItem('languageLoc', response.data.language);
                localStorage.setItem("username", response.data.username);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("profile", response.data.profile);
                localStorage.setItem("havePinCode", response.data.havePinCode);
                localStorage.setItem("company", response.data.company);

                // Set location by default
                let locations = JSON.parse(response.data.locations);
                if (locations.length === 1 && response.data.profile > 3) {
                    localStorage.setItem("currentLocation", locations[0].location_id);
                } else {
                    localStorage.setItem("currentLocation", "");
                }

                localStorage.setItem("locations", response.data.locations);
                localStorage.setItem("numberInformation", btoa(btoa(response.data.numberInformation)));
                localStorage.setItem("action", response.data.action);
                firebase.post('token_users', { user_email: response.data.username, user_token: response.data.token, user_status: 1 })
                    .then(response => {
                        if (response.success) {
                        localStorage.setItem("documentSesionFirebase", response.id);
                    } else {
                        console.log('Error al guardar el documento:', response.error);
                    }
                    });
                setLoader(false);

                if (localStorage.getItem('user')) {
                    nav("/");
                }
            }else{
                setLoader(false);
                setError(t(response.data.message));
            }
        }).catch(error => {
            setLoader(false);
            setError(t(error.response.data.message));
        });
    }

    const handleRegisterClick = () => {
        nav('/register');
    }

    const handleForgotPassword = () => {
        nav('/forgot');
    }

    return (
        logged && <>
            <div className="d-flex justify-content-center bg-login">
                <div className="login-container">
                    <div className="dark-mode-toggle button-togle-theme">
                        <button style={{background: 'transparent', borderColor:'transparent',}} onClick={()=>toggleDarkMode(true)} className="">
                            {isDarkMode ? <><span className="material-symbols-outlined" >light_mode</span><p>{t('toggleDarkMode.Light')}</p></>
                            :<><span className="material-symbols-outlined" >dark_mode</span><p>{t('toggleDarkMode.Dark')}</p></>}   
                        </button>
                    </div>
                    <div className="section-login login" style={{ maxWidth: "500px", width: "70%" }} >
                        { isDarkMode ? <img src={logoNegativo} alt="logo-brava" className="logo-login logo-l"/> :
                        <img src={logo} alt="logo-brava" className="logo-login logo-l"/> }
                        <form action="/" onSubmit={handleFormLogin}>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <TextField name="fuser" label={t('email')}
                                        value={loginForm.fuser}
                                        onChange={handleLogin}
                                        required variant="standard" size="small" />
                                </div>
                                <div className="col-md-12">
                                    <TextField type="password" name="fpass" label={t('password')}
                                        value={loginForm.fpass}
                                        onChange={handleLogin}
                                        required variant="standard" size="small" />
                                </div>
                            </div>
                            {error &&
                                <Alert className="mb-3" severity="error">{error}</Alert>
                            }
                            <p className='subtitle-text-login' style={{ textAlign: 'left' }}><a className="text-link" onClick={handleForgotPassword}>{t('forgotPassword')}</a></p>
                            <center>
                                <Button className="bold-btn-dark-big fs-5" type="submit">{t('singin')}</Button>
                            </center>
                        </form>
                        <p className='subtitle-text-login' style={{ textAlign: 'center', marginTop: '2rem'}}>{t('userRegister.new')} <a className="text-link" onClick={handleRegisterClick}>{t('userRegister.register')}</a></p>
                        <div style={{marginTop: '2rem', backgroundColor: '#7b90c4', height: 1}}></div>
                        <p className='subtitle-text-login' style={{ textAlign: 'center', marginTop: '1rem', fontSize: '15px' }}>{t('userRegister.agree')} <br/><a className="text-linkSub" href="https://posbrava.com/politicas-de-privacidad/" target="_blank">{t('userRegister.privacyPolicy')}</a></p>
                    </div>
                </div>
            </div>
            {
                loader &&
                <>
                    <div className="content-loader">
                        <CircularProgress />
                    </div>
                </>
            }
        </>
    )
}

export default Login;